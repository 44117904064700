<template lang="pug">
Popup
    template(slot="title") {{ $t('Inventory.addDetail_Header') }}
      .PopupHeader
        .tab
          Button(
              size="xsmall"
              id="btn-recipes-popup-tab-header-1"
              :class="{ 'isSelected' : activeTabIndex === 0 }",
              @click="tabChange(0)",
              type="button"
          ) {{ $t('Inventory.addDetail_Header_Tab_StockItem') }}
          Button(
              size="xsmall"
              id="btn-recipes-popup-tab-header-2"
              :class="{ 'isSelected' : activeTabIndex === 1 }",
              @click="tabChange(1)",
              type="button"
          ) {{ $t('Inventory.addDetail_Header_Tab_Recipe') }}

    template(slot="content")
      form.Form(
      v-if="renderComponent"
      id="inventory-add-stock-item-form",
      @submit.prevent="onSubmit('add-stock')"
      v-show="activeTabIndex === 0")

        .Form-item.required
          label.Form-item-label {{ $t('Inventory.addDetail_Header_StockItem_Label') }}
          .control
            custom-search(
              component-name="select-stock-item-add-stock-item-popup"
              name="stockItem"
              data-vv-as="Stok Malı"
              data-vv-scope="add-stock"
              v-model="form.stockItem"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('add-stock.stockItem') }"
              ref="firstInput"
              :on-search="searchStockItems"
              :options="searchedStockItems"
              label="name"
              :loading="isSearchStockItem"
              :placeholder="$t('Inventory.addDetail_Header_StockItem_Placeholder')"
              :directSelectOption="directSelectOption"
              @input="selectStockItem")

                template(slot="no-options") {{ $t('Inventory.addDetail_Header_StockItem_NoData') }}

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('add-stock.stockItem')"
          ) {{ veeErrors.first('add-stock.stockItem') }}

        .Form-item
          label.Form-item-label {{ $t('Inventory.addDetail_Header_Unit_Label') }}
          .control.with-icon
            .form-item-select
                Icon.icon-down-arrow(name="icon-down-arrow")
                select.select(
                  id="select-unit-add-stock-item-popup"
                  name="unitId"
                  v-model="form.unitId"
                  data-vv-scope="add-stock"
                  :class="{ 'is-danger': veeErrors.has('add-stock.unitId') }"
                  @change="addCountingItem({unitId: form.unitId})"
                  :disabled="filteredUnits.length === 0")
                    option(
                      value="-1"
                      id="option-unit-add-stock-item-popup-unselected"
                    ) {{ $t('ListsInventory.formField_Unit_Unselected') }}
                    option(
                      v-for="unit in filteredUnits"
                      :key="unit.id"
                      :disabled="unit.isUsed"
                      :value="unit.id"
                      :id="`option-unit-add-stock-item-popup-${unit.name}`"
                    ) {{ unit.name }}
          p.Form-item-help.is-danger(
              v-show="veeErrors.has('add-stock.unitId')"
          ) {{ veeErrors.first('add-stock.unitId') }}

        .selectedItemsTable
          .selectedItems(v-for="(item, index) in form.countings", :key="index")

            .selectedItem
              span.itemName {{ item.name }}
                .selectedItemHelperText.is-danger(
                  v-show="veeErrors.has(`add-stock.quantity-${index}`)"
                ) {{ veeErrors.first(`add-stock.quantity-${index}`) }}

              span.selectedInputSpan.align-right
                customNumberInput.quantity.ff-mono.no-spinners.selectedInput(
                  :id="`selected-units-input-add-stock-item-popup`"
                  :name="`quantity-${index}`"
                  v-model="form.countings[index].quantity"
                  data-vv-as="Miktar"
                  data-vv-scope="add-stock"
                  v-validate="{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                  :class="{ 'is-danger': veeErrors.has(`add-stock.quantity-${index}`) }")

              span.deleteButton(
                @click="removeCountingItem(item.id)"
              )
                Icon.m-left-5(name="icon-popup-close",
                :id="`selected-stock-item-delete-${index}`"
                )
      form.Form(
        id="inventory-add-recipe-item-form"
        @submit.prevent="onSubmit('add-recipe')"
        v-show="activeTabIndex === 1")

        .Form-item.required
          label.Form-item-label {{ $t('Inventory.addDetail_Header_Recipe_Label') }}
          .control
            custom-search(
              component-name="select-recipe-add-recipe-popup"
              name="recipeItem"
              v-model="form.recipe"
              data-vv-as="Reçete"
              data-vv-name="recipeItem"
              data-vv-scope="add-recipe"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('add-recipe.recipeItem') }"
              ref="secondInput"
              :on-search="searchRecipes"
              :options="searchedRecipes"
              label="name"
              :loading="isSearchRecipes"
              :placeholder="$t('Inventory.addDetail_Header_Recipe_Placeholder')"
              :directSelectOption="recipeDirectSelectOption"
              @input="selectRecipe")
                template(slot="no-options") {{ $t('Inventory.addDetail_Header_Recipe_NoData') }}

            .Form-item.portion-info(v-if="form.recipe")
              span.title.c-success {{ $t('Inventory.addDetail_Header_PortionInfo') }}
              span {{ portionQuantity }}

            p.Form-item-help.is-danger(
              v-show="veeErrors.has('add-recipe.recipeItem')"
            ) {{ veeErrors.first('add-recipe.recipeItem') }}

        .Form-item.required
          label.Form-item-label {{ $t('Inventory.addDetail_Header_RecipeQuantity') }}
          .control
            input.txt.quantity.ff-mono.no-spinners(
              id="input-recipes-popup-quanity"
              type="number"
              name="actualQuantity"
              :class="{ 'is-danger': veeErrors.has('add-recipe.actualQuantity') }"
              data-vv-as="Miktar"
              data-vv-scope="add-recipe"
              autocomplete="off"
              step="any"
              v-validate="{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
              v-model.number="form.actualQuantity")

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('add-recipe.actualQuantity')"
          ) {{ veeErrors.first('add-recipe.actualQuantity') }}

      .Form-item(v-if="isRecipeAddedBefore")
        DualRadioButton(
          :isActive="isOverwrite",
          :isActiveLabel="$t('Inventory.Add_Detail_Overwrite_Active')",
          :isPassiveLabel="$t('Inventory.Add_Detail_Overwrite_Passive')"
          :headerLabel="$t('Inventory.Add_Detail_Overwrite_Header')"
          @delegateIsActiveValue="changeOverwrite",
          )
    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
          id="checkbox-add-stock-item-popup"
          :label="$t('Inventory.addDetail_Header_Checkbox_AddAnotherData')"
          v-model="saveAndNew"
        )

      Button.uppercase(
        primary,
        type="submit",
        size="medium",
        variant="full",
        :disabled="isRunAction",
        :isLoading="isRunAction",
        id="button-list-inventory-add-detail",
        :form="activeTabIndex === 0 ? 'inventory-add-stock-item-form' : 'inventory-add-recipe-item-form'"
      )
        span {{ $t('Inventory.addDetail_Header_AddButton_Label') }}

</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import DualRadioButton from '@/view/global/dual-custom-radio'
import { vueWaitLoader } from '@/utils/baseOperations'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'

export default {
  name: 'InventoryListDetailForm',
  mixins: [getQuantityFromBarcode],
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeTabIndex: 0,
      saveAndNew: true,
      searchedStockItems: [],
      searchedRecipes: [],
      form: {
        stockItem: null,
        unitId: '-1',
        countings: [],
        recipe: null,
        recipeHeader: null,
        actualQuantity: 0
      },
      isOverwrite: true,
      recipeSearch: null,
      renderComponent: true
    }
  },
  components: {
    DualRadioButton
  },
  watch: {
    'form.recipe' () {
      this.isOverwrite = true
    }
  },
  created () {
    if (this.$route.name === 'InventoryAddRecipe') this.tabChange(1)
  },
  computed: {
    ...mapGetters('Units', [
      'findUnit',
      'getRelatedUnitList'
    ]),
    ...mapGetters('Inventories', ['inventoryRecipeCountList']),
    isSearchRecipes () {
      return this.$wait.is('searchRecipeItemByName')
    },
    isSearchStockItem () {
      return this.$wait.is('searchStockItem')
    },

    isBarcodeSearch () {
      return this.searchedStockItems.length === 1 &&
        this.searchedStockItems[0].matchType === 2
    },

    directSelectOption () {
      return this.searchedStockItems.length === 1 &&
      this.searchedStockItems[0].matchType === 2
        ? this.searchedStockItems[0]
        : {}
    },
    isRecipeBarcodeSearch () {
      return this.searchedRecipes.length === 1 &&
        this.searchedRecipes[0].matchType === 2
    },
    recipeDirectSelectOption () {
      return this.isRecipeBarcodeSearch
        ? this.searchedRecipes[0]
        : {}
    },

    filteredUnits () {
      if (!this.form.stockItem) return []
      const baseUnitId = this.findUnit(this.form.stockItem.unitId).baseUnitId
      let relatedUnits = this.getRelatedUnitList(baseUnitId)
      return this.form.countings ? relatedUnits.filter(ul => !this.form.countings.find(c => c.id === ul.id)) : relatedUnits
    },
    portionQuantity () {
      return this.form.recipeHeader ? this.form.recipeHeader.portionQuantity + ' ' + this.form.recipeHeader.portionUnitName : null
    },
    isRunAction () {
      return this.$wait.is(['addRecipeToInventory', 'addStockItemToInventory', 'submitFormInventoryAddStock'])
    },
    isRecipeAddedBefore () {
      return this.form.recipe ? this.inventoryRecipeCountList.some(item => item.recipeHeaderId === this.form.recipe.id) : false
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Recipes', [
      'searchRecipeItemByName',
      'get_RECIPE_DETAIL'
    ]),
    ...mapActions('Inventories', [
      'get_INVENTORY',
      'addStockItemToInventory',
      'addRecipeToInventory',
      'getInventoryCountingByStockItemId'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    changeOverwrite (bool) {
      this.isOverwrite = bool
    },
    tabChange (index) {
      if (this.activeTabIndex === 0) {
        this.$validator.reset('add-stock')
      } else {
        this.$validator.reset('add-recipe')
      }
      this.activeTabIndex = index
    },
    getInventory () {
      this.$emit('getList')
    },
    searchStockItems (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.searchStockItem({ text: decoratedValue, types: [1, 2, 3] })
        .then(res => {
          this.searchedStockItems = res.data.items
          this.form.stockItem = res.data.items[0]
          if (this.isBarcodeSearch) {
            const baseUnitId = res.data.items[0].baseUnitId
            const parsedBarcode = this.getQuantityFromBarcode(search, baseUnitId)
            if (parsedBarcode.isWeighted) {
              const unit = this.findUnit(baseUnitId)
              this.form.countings.push({
                ...unit,
                quantity: parsedBarcode.quantity || null
              })
            }
            this.forceRerender()
          }
        })
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
   
    searchRecipes (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.recipeSearch = search
      this.searchRecipeItemByName({name: decoratedValue, types: [1, 2, 3]})
        .then(res => {
          if (res.data.recipeHeaderList.length > 0) {
            this.searchedRecipes = res.data.recipeHeaderList
            if (this.isRecipeBarcodeSearch) {
              this.selectRecipe(this.searchedRecipes[0])
            }
          }
        })
    },

    selectRecipe (value) {
      if (!value) return
      this.get_RECIPE_DETAIL({recipeHeaderId: value.id})
        .then(res => {
          if (res) {
            this.form.recipeHeader = res.data.recipeHeader
            if (this.isRecipeBarcodeSearch) {
              const unitId = res.data.recipeHeader.totalUnitId
              const search = this.recipeSearch
              const parsedBarcode = this.getQuantityFromBarcode(search, unitId)
              if (parsedBarcode.isWeighted) {
                this.form.actualQuantity = parsedBarcode.quantity
              }
            }
          }
        })
    },
        
    selectStockItem (value) {
      this.form.countings = []
      if (!value) return

      const params = {
        inventoryHeaderId: this.$route.params.id,
        stockItemId: value.id
      }

      this.getInventoryCountingByStockItemId(params)
        .then(res => {
          res.data.inventoryCountList.map(count => this.addCountingItem(count))
          this.addCountingItem({unitId: value.unitId})
        })
    },
   
    addCountingItem (param) {
      const unit = this.findUnit(param.unitId)
      const canAdd = unit && !this.form.countings.some(item => item.id === unit.id)
      if (!canAdd) return
      this.form.countings.push({
        ...unit,
        quantity: param.unitQuantity || null
      })
      this.form.unitId = '-1'
    },
    removeCountingItem (unitId) {
      this.form.countings = this.form.countings.filter(cl => unitId !== cl.id)
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formReset () {
      this.searchedStockItems = []
      this.searchedRecipes = []
      this.form = {
        stockItem: null,
        unitId: '-1',
        countings: [],
        recipe: null,
        recipeHeader: null,
        actualQuantity: 0
      }
    },

    onSubmit: vueWaitLoader(async function (scope) {
      await this.$validator.validateAll(scope).then(async result => {
        if (!result) return
        let inventoryHeaderId = this.$route.params.id
        if (this.activeTabIndex === 0) {
          let stockItemId = this.form.stockItem.id
          let countingList = this.form.countings
          await this.addStockItemToInventory({
            inventoryHeaderId,
            stockItemId,
            inventoryCountDetailList: countingList.map(cl => ({
              unitId: cl.id,
              actualQuantity: cl.quantity
            }))
          }).then(stockResult => {
            const message = this.$t('Inventory.addStock_successfully')
            if (stockResult) {
              if (!this.saveAndNew) {
                this.getInventory()
                this.closePopup()
              } else {
                this.formReset()
                this.$nextTick(() => {
                  this.$validator.reset(scope)
                })
                this.getInventory()
              }
              this.notifyShow({ message })
            }
          })
        } else {
          let recipeHeaderId = this.form.recipeHeader.id
          let actualQuantity = this.form.actualQuantity
          const isOverwrite = this.isOverwrite
          await this.addRecipeToInventory({
            inventoryHeaderId,
            recipeHeaderId,
            actualQuantity,
            isOverwrite
          }).then(recipeResult => {
            if (recipeResult) {
              if (!this.saveAndNew) {

                this.closePopup()
              } else {
                this.formReset()
                this.$nextTick(() => {
                  this.$validator.reset(scope)
                })
              }
              const message = this.$t('Inventory.addRecipe_successfully')
              this.notifyShow({ message })
              this.getInventory()
            }
          })
        }
      })
    }, 'submitFormInventoryAddStock')
  }
}
</script>

<style lang="scss" scoped>

    @import '~@/view/pages/Lists/styles/form-modal.scss';

    .PopupHeader {
        padding-bottom: 0;
        margin-top: 27px;
        margin-bottom: -28px;

        .tab {
            display: flex;

            button {
                margin-right: 20px;
                font-size: 1.6rem;
                padding-bottom: 6px;
                border-bottom: 4px solid transparent;
                font-weight: $font-weight-normal;

                &.isSelected {
                    pointer-events: none;
                    color: $color-primary;
                    border-bottom-color: $color-primary;
                }
            }
        }
    }

    .portion-info {
        display: flex;
        margin: 10px 5px;

        .title {
            margin-right: 10px;
        }
    }

    .Popup-info {
        margin-top: 10px;
        font-weight: $font-weight-bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 6px;
    }
    .control {
        &.with-icon {
            display: flex;
            .form-item-select {
                width: 100%;
            }
            .btn-add-items {
                width: auto;
                padding: 0;
                min-width: auto;
                height: 24px;
                margin-left: 20px;
                align-self: center;
                &:disabled {
                background: transparent !important;
                box-shadow: none !important;
                }
            }
        }
    }

    .selectedItemsTable {
        display: table;
        border-collapse: collapse;
        margin: 10px 0 20px 0;
        width: 100%;

        .selectedItems {
            display: table-row-group;
            border-bottom: 1px solid $color-gray;
            .selectedItem {
                display: table-row;
                span {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 10px 0;
                    color: $color-success;

                    &.itemName {
                        width: 50%;
                    }

                    &.align-right {
                        text-align: right;
                    }

                    .selectedInput {
                        min-height: 36px;
                        max-width: 80px;
                        margin-left: auto;
                    }

                    &.deleteButton {
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }

            .selectedItemHelperText {
              width: 100%;
              font-size: 14px;
              color: $color-warning;
            }
        }
    }

</style>
